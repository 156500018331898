import * as React from "react"
import Layout from "../components/Layout"
import {StaticImage} from "gatsby-plugin-image";
import '../styles/buy.scss'
import {graphql} from "gatsby";
import {Trans} from "gatsby-plugin-react-i18next";
import {Parallax} from "react-scroll-parallax";
import Mapa from "../images/inline/map.inline.svg"
import Sello from "../images/inline/sello.inline.svg"

const BuyPage = () => {
    return (
        <Layout footerTop={false}>
            <title><Trans>Where to buy</Trans></title>

            <section className="main_section">

                <div className="main_logo">
                    <StaticImage className="image" src="../images/ouressence/pina_tequila_ilustracion.svg" alt="pina"/>
                    <h2 className="to">
                        <Parallax speed={50} translateY={[-15, 15]}>
                            <Trans>Where to buy</Trans>
                        </Parallax>
                    </h2>
                </div>

                <div className="main_image">
                    <Parallax className={'image'} speed={50} translateY={[15, -15]}>
                        <StaticImage className="image" src="../images/buy/main-buy-image.webp" alt="botella_santaleza"/>
                    </Parallax>
                    <StaticImage className="stamp" src="../images/ouressence/sello_santaleza.svg"
                                 alt="sello santaleza"/>
                    <div className="main_scroll" onClick={() => {
                        typeof window !== "undefined" ? window[`scrollTo`]({top: 500, behavior: `smooth`}) : ''
                    }}>
                        <p className="spettw">SCROLL DOWN</p>
                        <StaticImage src="../images/ouressence/scroll_down.svg" alt="flecha abajo"/>
                    </div>

                </div>


            </section>

            <section className="buybody">
                {/* <div className="container-button">
                    <a className="tbutton btn btn-tienda" href="/"><Trans>Shop button</Trans></a>
                    <a className="tbutton btn btn-localizar" href="/"><Trans>product locator</Trans></a>
                </div>
                 <StaticImage className="vintage" src="../images/buy/text-vintage.png" alt="texto vintage"/> */}
                <iframe src="https://finder.vtinfo.com/finder/web/v2/iframe?custID=TJ4&UUID=GaO1Mie4wSjHwk20qMbHuZuIVK0cjBvHWKt6Q&themeVersion=3&theme=bs-simplex" id="finder" width="100%" height="700" frameBorder="0" scrolling="no" allow="geolocation;"></iframe>
            </section>


            <section className={'locale-map'}>
                <StaticImage className={'bg'} src="../images/buy/bg-where.jpg" alt="Where to buy?"/>
                <div className="left">
                    <h2 className="h1"><Trans>Contact us</Trans></h2>
                    <p className="subtitle">We're always happy to hear from you! Whether you have a question about our tequila, want to place an order, or just want to say hello, please don't hesitate to reach out to <a href="mailto:info@santaleza.com">info@santaleza.com</a>. Please be as specific as possible in your email. <br /><br /><Trans>Write us</Trans></p>
                </div>
                <div className="right">
                    <Mapa/>
                    <div className="sello">
                        <Sello></Sello>
                    </div>
                </div>
            </section>

            <section className="fourth-section buy">
                <div className="back-to-top" onClick={() => {
                    typeof window !== "undefined" ? window[`scrollTo`]({top: 0, behavior: `smooth`}) : ''
                }}>
                    <StaticImage className="up-arrow" src="../images/home/back-top.svg" alt="flecha arriba"/>
                    <p className="parrafo text-back">BACK <br/> TO TOP</p>
                </div>
            </section>
        </Layout>
    )
}

export default BuyPage


export const pageQuery = graphql`
     query BuyQuery ($language: String!) {
      locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    }
`